import React, {useEffect, useState} from 'react';
import pivotalService from '../services/pivotal.services';
import Profile from "../pojo/Profile";
import Cookies from 'js-cookie';
import {IStoryDetail} from "../pojo/Stories";

const PublicPage = () => {
    const [data, setData] = useState<Profile>(new Profile());
    const [token, setToken] = useState<string>(Cookies.get('TOKEN')!);
    const [projectId, setProjectId] = useState<string>(Cookies.get('PROJECT_ID')!);

    const [dataStoriesOngoing, setDataStoriesOngoing] = useState<Array<IStoryDetail>>([]);
    const [dataStoriesDone, setDataStoriesDone] = useState<Array<IStoryDetail>>([]);
    const [dataStoriesBackLog, setDataStoriesBackLog] = useState<Array<IStoryDetail>>([]);

    const getData = async () => {
        const query: Profile = await pivotalService.getMe();
        setData(query);
    };

    const applyToken = async () => {
        try {
            Cookies.set('TOKEN', token);
            window.location.reload();
        }catch (e) {
            console.log(e);
        }
    }

    const selectProject = async (e:any, val: string) => {
        try{
            Cookies.set('PROJECT_ID', val);
            setProjectId(val);
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    const generateReport = async () => {
        try {
            const ongoingresp: Array<IStoryDetail> = await pivotalService.getOngoing(data.username);

            const newOngoingResp = await Promise.all(ongoingresp.map(async (item)=>{
                const Blocker = await pivotalService.getBlockers(item.id);
                item.blocker = Blocker;
                return item;
            }));
            setDataStoriesOngoing(newOngoingResp);


            const doneresp: Array<IStoryDetail> = await pivotalService.getDone(data.username);
            setDataStoriesDone(doneresp);

            const backlogresp: Array<IStoryDetail> = await pivotalService.getBackLog(data.username);
            setDataStoriesBackLog(backlogresp)

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div style={{textAlign: 'left'}}>

            <label htmlFor="token">
                Access Token
            </label> &nbsp;
            <input
                id="token"
                type={'text'}
                value={token}
                onChange={val=> setToken(val.target.value)}
                style={{border: '0.5px solid'}}
            />
            <button
                onClick={applyToken}
                style={{border: '0.5px solid'}}
            >
                Apply
            </button>

            <table style={{textAlign: 'left'}}>
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>{data?.id}</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>{data?.name}</td>
                    </tr>
                    <tr>
                        <td>username</td>
                        <td>{data?.username}</td>
                    </tr>
                    <tr>
                        <td>initials</td>
                        <td>{data?.initials}</td>
                    </tr>
                </tbody>
            </table>

            <br/>
            <br/>

            Project List
            <table>
                <thead>
                    <tr>
                        <td>Project ID</td>
                        <td>Project Name</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.projects.map((item,index) => {
                            return(
                                <tr key={index}>
                                    <td>{item.project_id}</td>
                                    <td>{item.project_name}</td>
                                    <td>
                                        <button
                                            style={{border: '0.5px solid'}}
                                            onClick={(e) => selectProject(e,item.project_id)}
                                        >
                                            Select Project
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <br/>

            <label htmlFor="projectId">
                Selected Project ID
            </label> &nbsp;
            <input
                id="projectId"
                type={'text'}
                value={projectId}
                disabled={true}
                onChange={val=> setProjectId(val.target.value)}
                style={{border: '0.5px solid'}}
            />

            <br/>
            <br/>


            <button
                onClick={generateReport}
                style={{border: '0.5px solid'}}
            >
                Generate Report
            </button>

            <br/>
            <br/>
            {
                dataStoriesDone.map((item, index) => {
                    return(
                        <div key={index}>
                            [ok] #{item.id} {item.name}
                        </div>
                    )
                })
            }
            {
                dataStoriesOngoing.map((item, index) => {
                    return(
                        <div key={index}>
                            [ongoing] #{item.id} {item.name}
                            {
                                item?.blocker!.map((item2, index) => {
                                    return(
                                        <>
                                            <br/>
                                            {!item2.resolved ?
                                                <>&nbsp;  &nbsp; [blocker] {item2.description} </> : null
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            {
                dataStoriesBackLog.map((item, index) => {
                    return(
                        <div key={index}>
                            [backlog] #{item.id} {item.name}
                        </div>
                    )
                })
            }


        </div>
    )
}

export default PublicPage;
