import request from './api.service';
import { plainToClass } from 'class-transformer';
import Profile from "../pojo/Profile";

import Cookies from 'js-cookie';
import {IBlocker, IStoryDetail} from "../pojo/Stories";

const getMe = async (): Promise<Profile> => {
    const resp = await request.get(`/me`);
    return plainToClass(Profile, resp);
};

const getOngoing = async (owner: string): Promise<Array<IStoryDetail>> => {
    const resp: Array<IStoryDetail>  = await request.get(`/projects/${Cookies.get('PROJECT_ID')}/stories?date_format=millis&&filter=owner:${owner} updated_before:tomorrow state:started`);
    return resp;
};

const getDone = async (owner: string): Promise<Array<IStoryDetail>> => {
    const resp: Array<IStoryDetail> = await request.get(`/projects/${Cookies.get('PROJECT_ID')}/stories?date_format=millis&&filter=owner:${owner} updated:today state:finished,delivered,accepted`);
    return resp;
};

const getBackLog = async (owner: string): Promise<Array<IStoryDetail>> => {
    const resp: Array<IStoryDetail> = await request.get(`/projects/${Cookies.get('PROJECT_ID')}/stories?date_format=millis&&filter=owner:${owner} created_before:tomorrow state:unstarted`);
    return resp;
};

const getBlockers = async (stories: string): Promise<Array<IBlocker>> => {
    const resp: Array<IBlocker>  = await request.get(`/projects/${Cookies.get('PROJECT_ID')}/stories/${stories}/blockers`);
    return resp;
};

export default {
    getMe,
    getOngoing,
    getDone,
    getBackLog,
    getBlockers
}
